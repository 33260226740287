import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      {/* <h5>What I Offer</h5> */}
      <h2>Mobile Apps</h2>
      <div className="container services__container">
        
        <a href="https://play.google.com/store/apps/details?id=com.yuente.typepic" className="service">
          <img src="https://play-lh.googleusercontent.com/8mU5ACTfnSio3oLMtttMC4ilmN8ChsfqL0KxKGt3FlWtsxqaUeLLB8dMBkzvjrfs1Ls=w480-h960-rw"/>
          <h3>Type Pic</h3>
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.yuente.break4digitcode" className="service">
          <img src="https://play-lh.googleusercontent.com/5Ad8CkmNNGwYWBbxepgQOVha6YkmgFdJzESn0-6bwY9D2p1aV41hszIpAYW18vgzSEg=w480-h960-rw"/>
          <h3>Break 4 Digit Code</h3>
        </a>
        
        <a href="https://play.google.com/store/apps/details?id=com.yuente.ydsyokdilkelimeler" className="service">
          <img src="https://play-lh.googleusercontent.com/0XksYL31GOQTkmsf_6S28SnilQNiB4z2kXFK0w5Lzwpgte5SDkZ7R5Ji2Tn9mMwzJQ=w480-h960-rw"/>
          <h3>YDS YÖKDİL Kelimeler</h3>
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.hledya.todoornottodo" className="service">
          <img src="https://play-lh.googleusercontent.com/2x8EwXk3IuXU2avSGbmbiCgAiXMAF6dTW_N3_n6K2InIBupfKyHeJ1atTeU6jIZw368=w480-h960-rw"/>
          <h3>To Do or Not To Do</h3>
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.yuente.at" className="service">
          <img src="https://play-lh.googleusercontent.com/-w2_q6VhGtBdUi2KE2apsZqmiWzwC5UTgYMTx2SGUBtwgxpIkZVZUEtYgRNUjDLj-7g=w480-h960-rw"/>
          <h3>aat Game</h3>
        </a>
        
      </div>
    </section>
  );
};

export default Services;
