import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio-food-app.png";
import IMG2 from "../../assets/portfolio-openai-app.png";
import IMG3 from "../../assets/portfolio-electron-app.png";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Food Order App",
    description: "A simple react app that uses Redux for state management.",
    github: "https://github.com/HLEDYA/food-order-app",
    demo: "https://hledya.com/react-apps/foods-app/",
  },
  {
    id: 2,
    image: IMG2,
    title: "Open AI App",
    description:
      "This react app generates content by using Open AI API via a Nodejs backend.",
    github: "https://github.com/HLEDYA/openai-content-generation-app",
    demo: "https://hledya.com/react-apps/openai-app/",
  },
  {
    id: 3,
    image: IMG3,
    title: "An Electron App for Plotting",
    description:
      "This desktop app built with Electron and React plots the contents of a csv file.",
    github: "https://github.com/HLEDYA/electron-plotting-app",
    demo: null,
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h2>Websites & Desktop Apps </h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, description, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <p>{description}</p>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  Github
                </a>
                {demo && (
                  <a href={demo} className="btn btn-primary" target="_blank">
                    Live Demo
                  </a>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
