import React from "react";
import "./about.css";
import ME from "../../assets/hledya-logo.png";
import { FaMobile } from "react-icons/fa";
import { FiUsers, FiMonitor } from "react-icons/fi";

const About = () => {
  return (
    <section id="about">
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <p>
            Fullstack software development is the process of developing software
            from start to finish. This includes everything from designing the
            user interface to writing the code that makes the software work. As
            <b> HLEDYA Software</b>, we work with a variety of programming
            languages and tools, as well as have a good understanding of how
            software works.
          </p>

          <div className="about__cards">

            <a href="#portfolio" className="about__card">
              <FiMonitor className="about__icon" />
              <h5>Websites & Desktop Apps</h5>
            </a>

            <a href="#services" className="about__card">
              <FaMobile className="about__icon" />
              <h5>Mobiles Apps</h5>
            </a>

            <a href="#contact" className="about__card">
              <FiUsers className="about__icon" />
              <h5>Contact Us</h5>
            </a>

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
